import {toAbsoluteStatic} from "../helpers";

export default {
  'CanalPlus': toAbsoluteStatic('/static/images/canalplus.jpg'),
  'CMStarTimes': toAbsoluteStatic('/static/images/cmstartimes.jpg'),
  'CMEASYTV': toAbsoluteStatic('/static/images/easytv.jpg'),
  'NGDSTV': toAbsoluteStatic('/static/images/dstv.png'),
  'NGBAXI': toAbsoluteStatic('/static/images/dstv.png'),
  'MTN': toAbsoluteStatic('/static/images/mtn.jpg'),
  'ORANGE': toAbsoluteStatic('/static/images/orange.jpg'),
  'CAMTEL': toAbsoluteStatic('/static/images/camtel.jpg'),
  'NEXTTEL': toAbsoluteStatic('/static/images/nexttel.jpg'),
  'YOOMEE': toAbsoluteStatic('/static/images/yoomee.jpg'),
  'ENEO': toAbsoluteStatic('/static/images/logo-eneo.png'),
  'CAMWATER': toAbsoluteStatic('/static/images/logo-camwater.png'),
}
