import React from 'react';
import clsx from "clsx";
import HTooltip from "../components/HTooltip.tsx";
import {useTranslation} from "react-i18next";
import {KTIcon} from "../../helpers";

export interface FormLabelProps {
  label: string;
  name: string;
  help?: string;
  required?: boolean;
  hideBadge?: boolean;
  marginBottom?: number;
  id?: string;
  className?: string;
}

function FormLabel({label, name, help = undefined, required = true, hideBadge = false, marginBottom = 2, id, className}: FormLabelProps) {
  const {t} = useTranslation()
  return (
    <label htmlFor={id || `id_${name}`} className={clsx(`form-label align-items-center fs-5 fw-semibold mb-${marginBottom}`, className)}>
      <span>{label}</span>
      {help && (
        <HTooltip title={help}>
          <span>
            <KTIcon iconName={'information-5'} className={'fs-5 ms-2 text-primary'} />
            {/*<i className='fas fa-exclamation-circle ms-2 fs-7'/>*/}
          </span>
        </HTooltip>
      )}
      {!required && !hideBadge && (<span className="menu-badge">
          <span className="badge badge-light-dark fw-bold fs-7">{t('optional')}</span>
      </span>)}
    </label>
  );
}

export default FormLabel;
