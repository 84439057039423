import type {Action, ThunkAction} from "@reduxjs/toolkit"
import {combineSlices, configureStore} from "@reduxjs/toolkit"
import {RootState, setupListeners} from "@reduxjs/toolkit/query"
import {authSlice} from "./auth.ts";
import {applicationSlice} from "./application.ts";
import {configsSlice} from "./configs.ts";
import * as Sentry from "@sentry/react";
import {termsSlice} from "./terms.ts";
import {announcementsSlice} from "./announcements.ts";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineSlices(authSlice, applicationSlice, configsSlice, termsSlice, announcementsSlice)

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = (preloadedState?: Partial<RootState>) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });

  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        },
      }) //.concat(quotesApiSlice.middleware)
    },
    preloadedState,
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
