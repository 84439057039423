import React from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";

export interface PaginationProps {
  total: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
}

const Pagination = ({page, total, pageSize, setPage}: PaginationProps) => {
  const {t} = useTranslation()
  const totalPages = Math.ceil(total / pageSize);
  const start = (page - 1) * pageSize + 1;
  const end = Math.min(page * pageSize, total);

  return (
    <div className='d-flex flex-stack flex-wrap pt-0'>
      <div className='fs-6 fw-bold text-gray-700'>{t('paginationCount', {from: start, to: end, total})}</div>

      <ul className='pagination'>
        <li className='page-item previous'>
          <a className='page-link' onClick={() => {
            if (page > 1) {
              setPage(page - 1)
            }
          }}>
            <i className='previous'></i>
          </a>
        </li>

        {Array(totalPages).fill(0).map((_, index) => (
          <li className='page-item' key={index}>
            <a className={clsx('page-link', {active: page == index + 1})} onClick={() => {
              if (page != index + 1) {
                setPage(index + 1)
              }
            }}>
              {index + 1}
            </a>
          </li>
        ))}

        <li className='page-item next'>
          <a className='page-link' onClick={() => {
            if (page < totalPages) {
              setPage(page + 1)
            }
          }}>
            <i className='next'></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
