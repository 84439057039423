import React from 'react';
import {toAbsoluteStatic} from "../../../../_mesomb/helpers";
import clsx from "clsx";
import SVG from "react-inlinesvg";

const colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B']

export interface HIconProps {
  image?: string;
  text?: string;
  size: number;
  className?: string;
}

function HIcon({image, text, size, className}: HIconProps) {
  if (!image && !text) {
    return null;
  }
  return (
    <div className={clsx(`symbol symbol-${size}px`, className)}>
      {image ? (
        image.startsWith('<svg') ? <SVG src={image} width={size} height={size} /> : <img src={toAbsoluteStatic(image)} alt={text} />
      ) : (
        <span className="symbol-label text-inverse-primary fw-bold" style={{
          fontSize: size / 1.6,
          backgroundColor: colors[text!.length % colors.length]
        }}>{text!.charAt(0).toUpperCase()}</span>
      )}
    </div>
  )
}

export default HIcon;
