import React from 'react';
import {Modal} from 'react-bootstrap';
import {KTIcon} from "../../helpers";
import FormBuilder from "../form/FormBuilder.tsx";
import {FormFieldProps} from "../form/FormField.tsx";

export interface ModalFormBuilderProps {
  show: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  fields: FormFieldProps[];
  nbrCols?: number;
  formSchema: any;
  initialValues?: Record<string, any>;
  action: {path: string; method?: string, responseType?: string};
  onSubmit: (data: Record<string, any>) => void;
}

function ModalFormBuilder({show = false, handleClose, fields, title, subtitle, formSchema, action, onSubmit, initialValues, nbrCols}: ModalFormBuilderProps) {
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <div style={{width: '100%'}}>
          <h3 className="modal-title">{title}</h3>
          {subtitle && (<span className="fw-light">{subtitle}</span>)}
        </div>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <FormBuilder
          fields={fields}
          formSchema={formSchema}
          className={''}
          action={action}
          onSubmit={onSubmit}
          initialValues={initialValues}
          nbrCols={nbrCols}
        />
      </div>
    </Modal>
  );
}

export default ModalFormBuilder;
