import React, {JSX, useState} from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import HTooltip from "./HTooltip.tsx";
import {KTIcon} from "../../helpers";
import {FieldInputValue} from "../../../types";

export interface ActionButtonProps<T> {
  icon?: string | JSX.Element;
  title?: React.ReactNode;
  action: { path: string; method?: string }
  className?: string;
  onFinish?: (data: T) => void;
  data?: Record<string, FieldInputValue>;
  confirmation?: {title?: string; message: string}
  tooltip?: string;
  'data-cy'?: string;
}

function ActionButton<T>({title, icon, className = 'btn btn-light fw-bold flex-shrink-0', action, onFinish, data = {}, confirmation, tooltip, 'data-cy': dataCy}: ActionButtonProps<T>) {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const btn = (
    <button type="submit" className={clsx(className)} data-cy={dataCy} onClick={() => {
      const perform = () => {
        setLoading(true)
        if (action.method == 'delete' && data != null) {
          axios.request({url: action.path, method: action.method, data}).then(({data}) => {
            setLoading(false)
            if (onFinish) onFinish(data)
          }).catch(() => {
            setLoading(false)
          })
        } else {
          axios.request({url: action.path, method: action.method || 'post', data}).then(({data}) => {
            setLoading(false)
            if (onFinish) onFinish(data)
          }).catch(() => {
            setLoading(false)
          })
        }
      }
      if (confirmation) {
        Swal.fire({
          title: confirmation.title || t('Confirmation'),
          text: confirmation.message,
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: t('Yes_do_it'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary"
          }
        }).then((value) => {
          if (value.isConfirmed) {
            perform()
          }
        })
      } else {
        perform()
      }
    }}>
      {!loading && (
        <>
          {icon && <KTIcon iconName={String(icon)} className={'fs-3'} />}
          {title && title}
        </>
      )}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {className?.indexOf('btn-icon') < 0 && `${t('Please wait')}... `}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  );
  if (tooltip) {
    return <HTooltip title={tooltip}>{btn}</HTooltip>
  }
  return btn
}

export default ActionButton;
