import React from 'react';
import clsx from "clsx";
import {OtpInput as BaseOtpInput} from 'reactjs-otp-input';

function OtpInput({name, form, isSecure = false, length = 4, inputType = 'numeric', onComplete}) {
  return (
    <BaseOtpInput
      numInputs={length}
      onChange={function (otp: string): void {
        form.setFieldValue(name, otp);
        if (otp.length == length && onComplete) {
          onComplete(otp);
        }
      }}
      value={form.values[name]}
      isInputNum={inputType === 'numeric'}
      isInputSecure={isSecure}
      inputStyle={clsx('form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2')}
      data-cy={`input-${name}`}
    />
  );
}

export default OtpInput;
