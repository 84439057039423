import {ApplicationResponse, PaymentLinkModel} from "../../../../types";


export const mapApplicationModel = (data?: ApplicationResponse): ApplicationModel | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    last_activity: new Date(data.last_activity),
    access: data.access ? {
      ...data.access,
      is_admin: data.access.roles.includes('admin') || data.access.roles.includes('owner'),
      is_owner: data.access.roles.includes('owner'),
      is_developer: data.access.roles.includes('developer') || data.access.roles.includes('admin') || data.access.roles.includes('owner'),
      is_finance: data.access.roles.includes('fund_management') || data.access.roles.includes('admin') || data.access.roles.includes('owner'),
    } : undefined,
  }
}




export const mapCustomer = (data: Omit<Customer, 'created_at' | 'last_activity'> & {created_at: string, last_activity: string}): Customer => {
  let fullName;
  if (!data.first_name && !data.last_name) {
    fullName = data.email || data.phone;
  } else {
    fullName = `${data.first_name} ${data.last_name}`;
  }
  const location = `${data.town || ''}, ${data.region || ''}, ${data.country || ''}`.trim();
  const address = `${data.address1 || ''}, ${data.address2 || ''}`.trim();
  const displayAddress = `${address}\n${location}`.trim();

  return {
    ...data,
    created_at: new Date(data.created_at),
    last_activity: new Date(data.last_activity),
    address,
    fullName,
    displayAddress,
  }
}



export const get_name = (link: PaymentLinkModel) => {
  if (link.name) return link.name

  if (link.type === 'PAYMENT') return link.title

  const nbr_products = link.products.length
  return `${link.products[0]?.product.name} ${nbr_products > 1 ? `+${nbr_products - 1}` : ''}`
}


export const mapAnnouncementModel = (data: Record<string, any>): AnnouncementModel => {
  return {
    content: data.content,
    dismissalType: data.dismissal_type,
    publishEnd: data.publish_end ? new Date(data.publish_end) : undefined,
    publishStart: new Date(data.publish_start),
    title: data.title,
    id: data.id,
  }
}
