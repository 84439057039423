import React from 'react';
import HIcon from "../../../app/modules/payment/components/HIcon.tsx";
import icons from "../../utils/icons.ts";

export interface MediaInfoCellProps {
  media: {
    initial?: string;
    avatar?: string;
    title: string;
    subtitle: string;
    url?: string;
    merchant?: string;
  };
  hideTextIcon?: boolean;
}

function MediaInfoCell({media, hideTextIcon = false}: MediaInfoCellProps) {
  if (!media.title && !media.subtitle && !media.avatar) {
    return null;
  }


  return (
    <a href={media.url || '#'}>
      <div className='d-flex align-items-center'>
        <div className={'symbol symbol-40px me-2'}>
          <HIcon image={media.avatar} text={hideTextIcon ? undefined : media.initial || media.title} size={40}/>
        </div>
        <div className='d-flex flex-column'>
          <div className='text-gray-800 text-hover-primary mb-1'>
            {media.title}
          </div>
          <span className={'fw-light'}>
            {media.merchant && <img src={icons[media.merchant]} width={15} height={15} style={{borderRadius: 10, marginRight: 2}}/>}
            {media.subtitle}
          </span>
        </div>
      </div>
    </a>
  );
}

export default MediaInfoCell;
