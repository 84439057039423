import React from 'react';
import {Form, Formik} from "formik";
import FormField from "./FormField.tsx";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import axios from "axios";
import {processErrorResponse} from "../../utils";
import {FormAction, FormFieldProps, FormValues} from "../../../types";
import * as Yup from "yup";
import ButtonIndicator from "../components/ButtonIndicator.tsx";

export interface FormBuilderProps<T extends FormValues, S> {
  fieldsets: {
    title?: string;
    cols: {
      size?: string;
      fields: FormFieldProps<T>[]
    }[];
  }[];
  formSchema: Yup.ObjectSchema<T>;
  className?: string;
  action?: FormAction;
  onSubmit?: (data: S, values: T) => void;
  initialValues: T;
}

function FormBuilder<T extends FormValues, S>({fieldsets, formSchema, className = 'card', action, onSubmit, initialValues}: FormBuilderProps<T, S>) {
  const {t} = useTranslation();
  return (
    <Formik<T> validationSchema={formSchema} initialValues={initialValues} onSubmit={(values, actions) => {
      actions.setSubmitting(true)
      if (action) {
        axios.request<S>({url: action.path, method: action.method || 'post', data: {...values, ...(values.full_name || {}), ...(values.bank_rib || {})}, responseType: action.responseType || 'json'}).then(({data}) => {
        actions.setSubmitting(false)
        if(onSubmit) onSubmit(data, values)
        }).catch((error) => {
          actions.setSubmitting(false)
          processErrorResponse(error, actions.setErrors)
        })
      } else {
        if(onSubmit) onSubmit(values as unknown as S, values)
        actions.setSubmitting(false)
      }
    }}>
      {({isSubmitting, isValid, touched, errors}) => {
        return (
          <Form className={clsx('row', className)} id="kt_create_application" method="post">
            {fieldsets.map(({cols}, index) => (
              <div className={'row'} key={`fieldset-${index}`}>
                {cols.map(({size, fields}) => (
                  <div className={clsx(size ?? 'col-sm-12')} key={fields.map(f => f.name).join('-')}>
                    {fields.map(field => (
                      <FormField
                        key={String(field.name)}
                        touched={touched[field.name] as boolean}
                        error={errors[field.name] as string}
                        {...field}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))}
            {/*{Array(nbrCols).fill(0).map((item, index) => (
              <div className={`col-sm-${12 / nbrCols}`} key={`col-${index}`}>
                {fields.filter(f => (f.col || 1) === index + 1).map(field => {
                  if (field.type === 'group') {
                    return (
                      <div className='row'>
                        {field.items?.map(f => (
                          <div key={f.name} className={'col-sm-6'}>
                            <FormField
                              touched={touched}
                              errors={errors}
                              {...f}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  }
                  return (
                    <FormField
                      key={String(field.name)}
                      touched={touched}
                      errors={errors}
                      {...field}
                    />
                  )
                })}
              </div>
            ))}*/}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonIndicator
                type={'submit'}
                label={t('Submit')}
                disabled={isSubmitting || !isValid || !touched}
                loading={isSubmitting}
                data-cy={'submit-button'}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  );
}

export default FormBuilder;
