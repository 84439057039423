import React, {useEffect, useState} from 'react';
import MediaInfoCell from "../../../../_mesomb/partials/table/MediaInfoCell.tsx";
import {KTIcon, toAbsoluteStatic} from "../../../../_mesomb/helpers";
import NumberCell from "../../../../_mesomb/partials/table/NumberCell.tsx";
import DateCell from "../../../../_mesomb/partials/table/DateCell.tsx";
import ActionButton from "../../../../_mesomb/partials/components/ActionButton.tsx";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalProductForm from "./ModalProductForm.tsx";
import {ProductModel} from "../../../../types";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {selectProduct, selectProducts, setProduct, setProducts} from "../../../store/product.ts";
import HStatus from "../../../../_mesomb/partials/components/HStatus.tsx";
import Pagination from "../../../../_mesomb/partials/components/Pagination.tsx";
import clsx from "clsx";

const pageSize = 10;

function Products() {
  const [selected, setSelected] = useState<ProductModel | undefined>(undefined)
  const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState<'ACTIVE' | 'ARCHIVED'>('ACTIVE')
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const [page, setPage] = useState(1);
  const database = products.filter(p => status === 'ACTIVE' ? !p.archived : p.archived)

  useEffect(() => {
    axios.get<ProductModel[]>(`products/pull/`).then(({data}) => {
      dispatch(setProducts(data))
    });
  }, []);

  return (
    <>
      <div className={'card'}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            {/*<span className='card-label fw-bold fs-3 mb-1'>New Arrivals</span>*/}
            {/*<span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span>*/}
          </h3>
          <div className='card-toolbar'>
            <ul className='nav me-2'>
              <li className='nav-item'>
                <a
                  data-cy={'button-filter-active'}
                  className={clsx('nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1', {active: status === 'ACTIVE'})}
                  onClick={() => setStatus('ACTIVE')}
                >
                  {t('Active')}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  data-cy={'button-filter-archived'}
                  className={clsx('nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1', {active: status === 'ARCHIVED'})}
                  onClick={() => setStatus('ARCHIVED')}
                >
                  {t('Archived')}
                </a>
              </li>
            </ul>
            <a className='btn btn-sm btn-light-primary' data-cy={'button-create-product'} onClick={() => {
              setSelected(undefined)
              setShowModal(true)
            }}>
              <KTIcon iconName='plus' className='fs-2' />
              {t('New Product')}
            </a>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4' id={'table_products'}>
              <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-325px rounded-start'>{t('Name')}</th>
                <th className='min-w-125px'>{t('Price')}</th>
                <th className='min-w-125px'>{t('Sells')}</th>
                <th className='min-w-200px'>{t('Created At')}</th>
                <th className='min-w-150px'>{t('Status')}</th>
                <th className='min-w-200px text-end rounded-end'></th>
              </tr>
              </thead>
              <tbody>
              {database.slice((page - 1) * pageSize, page * pageSize).map((product) => (
                <tr key={product.id}>
                  <td data-cy={'product-name'}>
                    <MediaInfoCell
                      media={{
                        url: `/products/${product.id}/`,
                        title: product.name,
                        subtitle: product.description,
                        avatar: product.image_url ? toAbsoluteStatic(product.image_url) : undefined,
                      }}
                    />
                  </td>
                  <td>
                    <NumberCell
                      value={parseFloat(product.default_price ?? '0')}
                      currency={product.currency}
                    />
                  </td>
                  <td>
                    <NumberCell value={product.sells}/>
                    <DateCell value={product.last_sell} className={'text-muted fw-semibold text-muted d-block fs-7'}/>
                  </td>
                  <td>
                    <DateCell value={product.created_at}/>
                  </td>
                  <td>
                    <HStatus label={product.archived ? t('Archived'): t('Active')} status={product.archived ? 'ARCHIVED': 'ACTIVE'} />
                  </td>
                  <td className='text-end'>
                    <ActionButton<ProductModel>
                      icon={product.archived ? 'check-circle' : 'archive'}
                      action={{path: `/products/${product.id}/`, method: 'patch'}}
                      data={{archived: !product.archived}}
                      className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'}
                      tooltip={product.archived ? t('Activate this product') : t('Archive this product')}
                      onFinish={(updated) => {
                        dispatch(setProducts(products.map(p => p.id === updated.id ? updated : p)))
                      }}
                      data-cy={'action-toggle-status'}
                    />
                    <a
                      onClick={() => {
                        setSelected(product)
                        setShowModal(true)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      data-cy={'action-edit'}
                    >
                      <KTIcon iconName='pencil' className='fs-3'/>
                    </a>
                    <ActionButton
                      icon={'trash'}
                      action={{path: `/products/${product.id}/`, method: 'delete'}}
                      className={'btn btn-icon btn-bg-light btn-active-color-danger btn-sm'}
                      confirmation={{
                        title: t('Confirmation'),
                        message: t('Are you sur you want to preform this action? This action irreversible.'),
                      }}
                      onFinish={() => {
                        dispatch(setProducts(products.filter(p => p.id !== product.id)))
                        toast.info(t('Product successfully deleted'))
                      }}
                      data-cy={'action-delete'}
                    />
                  </td>
                </tr>
              ))}
              {database.length === 0 && (
                <tr>
                  <td colSpan={6} className='text-center'>
                    {t('No products found')}
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
        {database.length > 0 && (
          <div className={'card-footer border-0'}>
            <Pagination total={database.length} page={page} setPage={setPage} pageSize={pageSize} />
          </div>
        )}
      </div>

      <ModalProductForm
        show={showModal}
        onClose={() => setShowModal(false)}
        selected={selected}
        onFinish={(data) => {
          setShowModal(false);
          setSelected(undefined)
          dispatch(setProduct(data))
          // queryClient.invalidateQueries([`${QUERIES.PRODUCT_LIST}-${query}`])
          toast.info(selected ? t('Product successfully updated') : t('Product successfully created'))
        }}
      />

      <ToastContainer/>
    </>
  );
}

export default Products;
