import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {ProductModel} from "../../types";

export interface AuthSliceState {
  products: ProductModel[]
  product?: ProductModel
}

const initialState: AuthSliceState = {
  products: [],
  product: undefined,
}

export const productSlice = createAppSlice({
  name: 'product',
  initialState,
  reducers: create => ({
    setProducts: create.reducer((state, action: PayloadAction<ProductModel[]>) => {
      state.products = action.payload
    }),
    setProduct: create.reducer((state, action: PayloadAction<ProductModel | undefined>) => {
      state.product = action.payload;
      const index = state.products.findIndex(a => a.id === action.payload?.id)
      if (index === -1) {
        state.products.push(action.payload!)
      } else {
        state.products[index] = action.payload!
      }
    }),
  }),
  selectors: {
    selectProducts: product => product.products,
    selectProduct: product => product.product,
  },
});

export const { setProducts, setProduct } =
  productSlice.actions

export const { selectProducts, selectProduct } = productSlice.selectors
