import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {PaymentLinkModel} from "../../types";

export interface AuthSliceState {
  paymentLinks: PaymentLinkModel[]
  paymentLink?: PaymentLinkModel
}

const initialState: AuthSliceState = {
  paymentLinks: [],
  paymentLink: undefined,
}

export const paymentLinkSlice = createAppSlice({
  name: 'paymentLink',
  initialState,
  reducers: create => ({
    setPaymentLinks: create.reducer((state, action: PayloadAction<PaymentLinkModel[]>) => {
      state.paymentLinks = action.payload
    }),
    setPaymentLink: create.reducer((state, action: PayloadAction<PaymentLinkModel | undefined>) => {
      state.paymentLink = action.payload;
      const index = state.paymentLinks.findIndex(a => a.key === action.payload?.key)
      if (index === -1) {
        state.paymentLinks.push(action.payload!)
      } else {
        state.paymentLinks[index] = action.payload!
      }
    }),
  }),
  selectors: {
    selectPaymentLinks: paymentLink => paymentLink.paymentLinks,
    selectPaymentLink: paymentLink => paymentLink.paymentLink,
  },
});

export const { setPaymentLinks, setPaymentLink } =
  paymentLinkSlice.actions

export const { selectPaymentLinks, selectPaymentLink } = paymentLinkSlice.selectors
