import Swal from "sweetalert2";
import i18n from "../i18n";
import * as Sentry from "@sentry/react";
import {formatDate as fnsFormatDate} from "date-fns/format";
import {isToday} from "date-fns/isToday";
import {isYesterday} from "date-fns/isYesterday";
import {isThisYear} from "date-fns/isThisYear";
import icons from '../utils/icons.ts';
import {ServiceProvider} from "../../types";

export function random(length: number = 40) {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function debounce<F extends (...args: any) => void>(
  func: F,
  wait: number,
  immediate: boolean = false,
): F {
  let timeout: any = null;
  const _debounce = (...args: any[]) => {
    // @ts-ignore
    const context = this;
    const later = function __debounce() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
  _debounce.stop = () => clearTimeout(timeout);
  return _debounce as F;
}


export const camelize = (str: string) => {
  return str.toLowerCase().replace(/^(.)/, (match, chr) => chr.toUpperCase());
};

export const extract = (values: Record<string, any>, name: string) => {
  return name.split('.').reduce((acc, item) => acc && acc[item], values)
}

export const parseDjangoError = (json: any) => {
  if (!json) {
    return {};
  }
  const {code} = json;
  delete json.code;

  if (typeof code === 'object') {
    return Object.keys(code).reduce((acc, key) => ({[key]: json[key].join('\n'), ...acc}), {})
  }
}

export const processErrorResponse = (error, setErrors) => {
  const response = error.response?.data;
  Sentry.setContext('xhrresponse', response)
  const parsedErrors = parseDjangoError(response)
  if (parsedErrors) {
    setErrors(parsedErrors)
  } else {
    Swal.fire({
      title: i18n.t('Error'),
      text: response.detail || i18n.t('Default_failure_transaction_message'),
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: i18n.t('Ok_got_it'),
      customClass: {
        confirmButton: "btn fw-bold btn-light-primary"
      }
    })
  }
}

export const formatNumberAbbr = (value: number, options?: {decimal?: number; currency?: string}) => {
  if (value > 1000000) {
    return `${i18n.t('intlNumber', {value: value/1000000})}M`
  } else if (value > 1000) {
    return `${i18n.t('intlNumber', {value: value/1000})}K`
  }
  return i18n.t('intlNumber', {value: value})
};

const buildOperationForm = (operation, inputs: Record<string, Record<string, any>>, skips: Array<string>) => {
  for (const param of operation.params) {
    let key = param.key;
    if (key == 'receiver') {
      key = 'b_party'
    }
    if (inputs[key]) {
      if (param.type == 'CHOICE') {
        inputs[key].options = param.choices.map(c => ({...c, image: icons[c.value]}))
      } else if (param.type == 'MSISDN') {
        inputs[key].type = 'phone'
      }
      if (inputs[key].format == 'NUMBER') {
        inputs[key].type = 'number'
      }
      if (param.placeholders) {
        inputs[key].label = param.placeholders.en
      }
    }

    skips.push(key)
  }
  for (const key of Object.keys(inputs)) {
    if (skips.includes(key)) {
      continue
    }
    delete inputs[key]
  }
  return inputs;
}

export const buildServiceForm = (operation, configs) => {
  const fields: Record<string, Record<string, any>> = {
    b_party: {
      name: 'b_party',
      label: i18n.t('Receiver Phone'),
      type: 'text',
      showCountryCode: false,
      placeholder: '670000000'
    },
    amount: {
      name: 'amount',
      label: i18n.t('Amount'),
      type: 'amount'
    },
    merchant: {
      name: 'merchant',
      type: 'select',
      label: i18n.t('Merchant')
    },
    product: {
      name: 'product',
      type: 'megaOption',
      label: i18n.t('Product'),
      itemClassName: 'col-lg-4',
    },
    account: {
      name: 'account',
      type: 'select',
      label: i18n.t('Account'),
      options: []
    },
  }

  // fields.account.options = configs.accounts.filter(a => a.provider === 'MESOMB').map(a => ({
  //   value: String(a.identifier),
  //   label: `${a.service_key} (${a.name})`,
  //   description: i18n.t('fees_rate', {rate: a.out_rate}),
  //   image: a.image
  // }))

  return buildOperationForm(operation, fields, ['provider', ...(['TVPurchase', 'BundlePurchase'].includes(operation.name) ? ['product'] : [])])
}

export const countryFlagUrl = (code?: string): string => {
  return `https://s3.us-west-2.amazonaws.com/public.hachther.com/flags/png100px/${code?.toLowerCase()}.png`;
};

export const formatDateTime = (date: Date) => {
  if (isToday(date)) {
    return i18n.t('Today_at', {time: fnsFormatDate(date, 'p')})
  }
  if (isYesterday(date)) {
    return i18n.t('Yesterday_at', {time: fnsFormatDate(date, 'p')})
  }

  if (isThisYear(date)) {
    return i18n.t('Date_format', {day: fnsFormatDate(date, 'MMM, dd'), time: fnsFormatDate(date, 'p')})
  }
  return i18n.t('Date_format', {day: fnsFormatDate(date, 'PP'), time: fnsFormatDate(date, 'p')})
}

export const getPaymentMethod = (country: string): Pick<ServiceProvider, 'key' | 'service_name' | 'logo'>[] => {
  return {
    CM: [
      {
        key: 'MTN',
        service_name: 'Mobile Money',
        logo: '/media/providers/logo-momo.jpg'
      },
      {
        key: 'ORANGE',
        service_name: 'Orange Money',
        logo: '/media/providers/orange-money.jpg'
      }
    ]
  }[country]!;
}
