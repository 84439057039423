import React from 'react';
import {formatDate} from "date-fns/format";
import {formatDateTime} from "../../utils";

function DateCell({value, format = 'PPpp'}: {value?: string; format?: string}) {
  if (!value) {
    return null;
  }
  return <span>{formatDateTime(value)}</span>;
}

export default DateCell;
