import React, {useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_mesomb/assets/ts/components'
import {useTranslation} from "react-i18next";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import i18n from '../../../../_mesomb/i18n/index.ts'
import LoginOptions from "./LoginOptions.tsx";
import {useAppDispatch} from "../../../store/hooks.ts";
import {Helmet} from "react-helmet";

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password2: '',
  agree: false,
}

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, i18n.t('Minimum_characters', {min: 3})),
    // .max(50, 'Maximum 50 symbols'),
  email: Yup.string()
    .email(i18n.t('Invalid email address'))
    .min(3, i18n.t('Minimum_characters', {min: 3}))
    // .max(50, 'Maximum 50 symbols')
    .required(i18n.t('Field_is_required')),
  last_name: Yup.string()
    .min(3, i18n.t('Minimum_characters', {min: 3}))
    // .max(50, 'Maximum 50 symbols')
    .required(i18n.t('Field_is_required')),
  password: Yup.string()
    .min(3, i18n.t('Minimum_characters', {min: 3}))
    // .max(50, 'Maximum 50 symbols')
    .required(i18n.t('Field_is_required')),
  password2: Yup.string()
    .min(3, i18n.t('Minimum_characters', {min: 3}))
    // .max(50, 'Maximum 50 symbols')
    .required(i18n.t('Password confirmation is required'))
    .oneOf([Yup.ref('password')], i18n.t('Your passwords didn\'t match')),
  agree: Yup.bool().required(i18n.t('You must accept the terms and conditions')),
})

export function SignUp({onLogin}) {
  const {t} = useTranslation()
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  if (params.get('email')) {
    initialValues.email = params.get('email')
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting, setErrors}) => {
      setSubmitting(true)
      if (localStorage.getItem('invitation') != null) {
        values.invitation = localStorage.getItem('invitation')
      }
      onLogin('signup', values, setErrors).then(() => {
        setSubmitting(false)
      }).catch(e => {
        setSubmitting(false)
      });
      /*setSubmitting(true)
      axios.post(setAuthUrl('auth/signup'), values).then(async ({data}) => {
        if (data.meta.is_authenticated) {
          const {data: profile} = await axios.get('profile/')
          dispatch(setUser(profile))
          window.location.href = '/dashboard'
          return
        }
        setSubmitting(false)
        window.location.href = '/dashboard'
      }).catch(error => {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors.reduce((acc, item) => ({...acc, [item.param]: item.message}), {}))
        } else {
          setStatus(error.message)
        }
        setSubmitting(false)
      })*/
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-gray-900 fw-bolder mb-3'>{t('Sign Up')}</h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6'>{t('Enter your details to create your account')}</div>
        </div>
        {/* end::Heading */}

        {/* begin::Login options */}
        <LoginOptions onLogin={onLogin}/>
        {/* end::Login options */}

        <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>{t('Or with email')}</span>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group First_Name */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t('First Name')}</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('first_name')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.first_name && formik.errors.first_name,
              },
              {
                'is-valid': formik.touched.first_name && !formik.errors.first_name,
              }
            )}
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.first_name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-8'>
          {/* begin::Form group Last_Name */}
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t('Last Name')}</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('last_name')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.last_name && formik.errors.last_name,
              },
              {
                'is-valid': formik.touched.last_name && !formik.errors.last_name,
              }
            )}
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.last_name}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Enter a Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={t('Your password')}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            {t('Use 8 or more characters with a mix of letters, numbers & symbols.')}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t('Confirm Password')}</label>
          <input
            type='password'
            placeholder={t('Confirm your password')}
            autoComplete='off'
            {...formik.getFieldProps('password2')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password2 && formik.errors.password2,
              },
              {
                'is-valid': formik.touched.password2 && !formik.errors.password2,
              }
            )}
          />
          {formik.touched.password2 && formik.errors.password2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password2}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('agree')}
            />
            <span>
            {t('I Accept the')}{' '}
              <Link
                to={'/terms-and-conditions'}
                target='_blank'
                className='ms-1 link-primary'
              >
              {t('Terms & Conditions')}
            </Link>
            .
          </span>
          </label>
          {formik.touched.agree && formik.errors.agree && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.agree}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <ButtonIndicator
            type={'submit'}
            label={t('Submit')}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.agree}
            className='btn btn-lg btn-primary w-100 mb-5'
          />

          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              {t('Cancel')}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
      <Helmet>
        <title>{t('Sign Up')}</title>
      </Helmet>
    </>
  )
}
