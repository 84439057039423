import React from 'react';
import {formatDateTime} from "../../utils";

function DateCell({value, className}: {value?: Date; format?: string; className?: string}) {
  if (!value) {
    return null;
  }
  return <span className={className}>{formatDateTime(value)}</span>;
}

export default DateCell;
