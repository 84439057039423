import React from 'react';
import {Modal} from 'react-bootstrap';
import {KTIcon} from "../../helpers";
import FormBuilder, {FormBuilderProps} from "../form/FormBuilder.tsx";
import {FormikValues} from "formik/dist/types";

export interface ModalFormBuilderProps<T extends FormikValues, S> extends FormBuilderProps<T, S> {
  show: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;
}

function ModalFormBuilder<T extends FormikValues, S>({show = false, handleClose, fieldsets, title, subtitle, formSchema, action, onSubmit, initialValues}: ModalFormBuilderProps<T, S>) {
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header' data-cy={'modal-header'}>
        <div style={{width: '100%'}}>
          <h3 className="modal-title" data-cy={'modal-title'}>{title}</h3>
          {subtitle && (<span className="fw-light" data-cy={'modal-subtitle'}>{subtitle}</span>)}
        </div>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <FormBuilder<T, S>
          fieldsets={fieldsets}
          formSchema={formSchema}
          className={''}
          action={action}
          onSubmit={onSubmit}
          initialValues={initialValues}
        />
      </div>
    </Modal>
  );
}

export default ModalFormBuilder;
