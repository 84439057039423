import React, {useMemo} from 'react';
import ModalFormBuilder from "../../../../_mesomb/partials/components/ModalFormBuilder.tsx";
import I18n from "../../../../_mesomb/i18n";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {ProductModel} from "../../../../types";

export interface ModalProductFormProps {
  show: boolean;
  onClose: () => void;
  selected?: ProductModel;
  onFinish?: (data: ProductModel) => void;
}

function ModalProductForm({show, onClose, selected, onFinish}: ModalProductFormProps) {
  const {t} = useTranslation()

  const formSchema = useMemo(() => (Yup.object().shape({
    name: Yup.string().required(t('Field_is_required')),
    description: Yup.string(),
    default_price: Yup.number(),
    price_type: Yup.string().oneOf(['OneTime', 'Recurring']).required(t('Field_is_required')),
    image: Yup.string(),
    price_interval: Yup.string().oneOf(['daily', 'weekly', 'monthly', 'yearly']),
    // b_party: Yup.string().matches(/^[0-9]{8,9}$/, t('Invalid_phone_number')).required(t('Field_is_required')),
  })), [])
  type FormProductValues = Yup.InferType<typeof formSchema>

  return (
    <ModalFormBuilder<FormProductValues, ProductModel>
      show={show}
      title={selected ? t('Edit Product') : t('New Product')}
      fieldsets={[{
        cols: [{
          size: 'col-lg-9 col-md-12',
          fields: [
            {
              type: 'text',
              name: 'name',
              label: t('Name'),
              help: t('Product_name_help'),
              placeholder: 'Premium Plan, sunglasses, etc.'
            },
            {
              type: 'textarea',
              name: 'description',
              label: t('Description'),
              help: t('Product_description_help')
            },
          ]
        }, {
          size: 'col-lg-3 col-md-12',
          fields: [
            {
              name: 'image',
              label: I18n.t('Image'),
              type: 'dropzone',
              required: false,
              help: t('Product_image_help'),
              col: 2,
              placeholder: t('Upload image'),
              file: selected?.image,
            },
          ]
        }]
      }, {
        cols: [{
          fields: [
            {
              type: 'number',
              name: 'default_price',
              label: t('Price'),
              placeholder: '0.0'
              // help: t('Product_price_help')
            },
            {
              name: 'price_type',
              label: I18n.t('Price Type'),
              type: 'radio',
              options: [
                {value: 'OneTime', label: t('One Time')},
                {value: 'Recurring', label: t('Recurring')},
              ],
              help: t('Product_price_type_help'),
              orientation: 'horizontal'
            },
            {
              name: 'price_interval',
              label: I18n.t('Price Interval'),
              type: 'radio',
              options: [
                {value: 'daily', label: t('Daily')},
                {value: 'weekly', label: t('Weekly')},
                {value: 'monthly', label: t('Monthly')},
                {value: 'yearly', label: t('Yearly')},
              ],
              orientation: 'horizontal'
            },
          ],
        }]
      }]}
      formSchema={formSchema}
      handleClose={onClose}
      action={{
        path: selected ? `/products/${selected.id}/` : `/products/`,
        method: selected ? 'patch' : 'post'
      }}
      onSubmit={(data) => {
        if(onFinish) onFinish(data)
        // setShowModal(false);
        // setSelected(false)
        // // queryClient.invalidateQueries([`${QUERIES.PRODUCT_LIST}-${query}`])
        // toast.info(selected ? t('Product successfully updated') : t('Product successfully created'))
        // ref.current?.click()
        // onClose && onClose()
      }}
      initialValues={{
        name: selected?.name ?? '',
        description: selected?.description,
        default_price: selected?.default_price ? parseFloat(selected?.default_price) : undefined,
        price_type: selected?.price_type ?? 'OneTime',
        price_interval: selected?.price_interval,
        image: selected?.image?.id ? String(selected.image.id) : undefined,
      }}
    />
  );
}

export default ModalProductForm;
