import React from 'react';

export const THEMES: Record<string, string> = {
  ONGOING: 'primary',
  ALERT: 'primary',
  COLLECTED: 'warning',
  PENDING: 'warning',
  SUSPENDED: 'warning',
  WARNING: 'warning',
  ARCHIVED: 'warning',
  CLOSED: 'warning',
  REJECTED: 'danger',
  STARTED: 'danger',
  FAILED: 'danger',
  ERRORED: 'danger',
  INACTIVE: 'danger',
  FAILURE: 'danger',
  CRITICAL: 'danger',
  DEACTIVATED: 'danger',
  ERROR: 'danger',
  SUCCESS: 'success',
  VERIFIED: 'success',
  SUCCESSFUL: 'success',
  SUCCESSFULL: 'success',
  REFUNDED: 'success',
  ACTIVE: 'success',
  ACTIVATED: 'success',
  INFO: 'success',
  OPEN: 'success',
  CANCELLED: 'dark',
  NOTICE: 'dark',
  DEBUG: 'info',
}

function HStatus({label, status, 'data-cy': dataCy}: {label: string; status: string, 'data-cy'?: string}) {
  return (
    <span className={`badge badge-${THEMES[status]} fs-7 fw-semibold`} data-cy={dataCy}>{label}</span>
  );
}

export default HStatus;
